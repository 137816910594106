<template>
  <ListItem/>
    <router-view/>
    <!-- <div> -->
      <!-- <bread-crumb></bread-crumb> -->
    <div class="footerItem">
      <footer-item/>
    </div>
    <div v-if="show" class="background_cookie">
      <div v-if="show" class="container_cookie">
        <div  role="dialog">
          <div id="cookie-notice">
            <div style="position:relative">
              <div class="container-p-link">
                <p class="p-link-cookies"  @click="DeclineCookieConsent">Continuez sans accepter<span>&#8594;</span></p>
              </div>
              <div id="cookie-title-website">
                <h4>{{ cookieTitle }}</h4>
              </div>
              <div id="cookie-title-welcome">
                <h4>{{ welcomeTitle }}</h4>
              </div>
              <div id="cookie-message">
                <p>
                  {{ cookieMessage }}
                  <a v-bind="{href, target}">{{ info }}</a></p>
              </div>
            </div>
            <div id="cookie-button">
              <button class="btn1"  @click="AcceptCookieConsent">Accepter</button>
              <button class="btn1"  @click="DeclineCookieConsent">Refuser</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </template>

<script>
import ListItem from '../src/components/ListItem.vue'
import FooterItem from './components/FooterItem'
// import breadCrumb from '../src/components/breadCrumb.vue'

export default {
  components: {
    ListItem,
    FooterItem
    // breadCrumb
  },
  props: {
    cookieTitle: {
      type: String,
      default: "L'HAIR DE RIEN"
    },
    welcomeTitle: {
      type: String,
      default: 'Bienvenue sur le site lhairderien-perruque.fr '
    },
    cookieMessage: {
      type: String,
      default: "Nous utilisons des cookies pour améliorer la pertinence de notre site Internet lorsque vous interagissez avec lui. Grâce aux cookies, nous comprenons mieux l'utilisation que vous faites de nos sites Internet, ce qui nous permet de vous proposer des contenus sur mesure. Pour plus d'informations sur les différents cookies que nous utilisons, lisez la Déclaration de confidentialité. Si vous souhaitez modifier vos paramètres et vos préférences en matière de cookies, utilisez l'Outil de gestion des cookies. "
    },
    href: {
      type: String,
      default: '/gestion-cookies'
    },
    target: {
      type: String,
      default: '_blank'
    },
    info: {
      type: String,
      default: "Plus d'info"
    },
    cookieName: {
      type: String,
      default: 'user-cookie-consent'
    },
    cookieValue: {
      type: Number,
      default: 1
    },
    cookieExpires: {
      type: Number,
      default: 30 // 30 days
    },
    cookiePath: {
      type: String,
      default: '/'
    }
  },
  data () {
    return {
      show: true,
      kalendes: { src: 'https://www.kalendes.com/widget/widget.js?onload=kalendesLoaded', type: 'text/javascript' },
      // crumbs: ['']
      mobileView: true,
      showNav: false,
      slides: [
        {
          id: 1,
          url: require('./assets/images_perruques/perruque_medicale.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques medicales',
          price: 350,
          description: 'Remboursées par la sécurité sociale',
          to: '/perruques-medicales-femmes'
        },
        {
          id: 2,
          url: require('./assets/images_perruques/perruque_naturelle.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruque naturelles',
          price: 350,
          description: 'perruque en vrais cheveux 100% naturels Remy Hair pour une longevité maximum',
          to: '/perruques-cheveux-naturels'
        },
        {
          id: 3,
          url: require('./assets/images_perruques/perruque_prime.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques fibre mixte',
          price: 350,
          description: "L'avantage du chebveux naturel et de la fibre synthétique",
          to: '/perruques-fibre-prime'
        },
        {
          id: 4,
          url: require('./assets/images_perruques/perruque_relooking.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques petits prix',
          price: 350,
          description: 'Sans remboursement sécu pour des budgets plus modérés',
          to: '/perruques-relooking'
        },
        {
          id: 5,
          url: require('./assets/images_perruques/perruque_lace.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques type lace wig',
          price: 350,
          description: 'chevelure ultra réaliste et indetectable 100% personnalisable',
          to: '/perruques-type-lace'
        },
        {
          id: 6,
          url: require('./assets/perruque_homme/ew_hairformance_justin_4.webp'),
          alt: 'perruque homme cheveux poivre et sel coupe courte',
          name: 'Perruques homme',
          description: 'Ligne de perruques dediée aux hommes remboursées par la sécu. Coupe personnalisable.',
          to: '/perruques-homme'
        }
      ],
      photos: [
        { thumbnail: 'https://lhairderien.fr/wp-content/uploads/2021/12/femme-1.jpg' },
        { thumbnail: 'https://lhairderien.fr/wp-content/uploads/2021/12/femme-1.jpg' },
        { thumbnail: 'https://lhairderien.fr/wp-content/uploads/2021/12/femme-1.jpg' },
        { thumbnail: 'https://lhairderien.fr/wp-content/uploads/2021/12/femme-1.jpg' }
      ]
    }
  },
  beforeMount () {
    this.show = this.cookie
    // console.log(!this.show)
  },
  // mounted () {
  //   // if (!this.show) {
  //   document.body.style.overflow = 'auto'
  //   // }
  // },
  methods: {
    AcceptCookieConsent () {
      this.show = false
      // console.log(!this.show)
      this.setCookie(
        this.cookieName,
        this.cookieValue,
        this.cookieExpires,
        this.cookiePath
      )
      // if (this.show) {
      //   document.body.style.overflow = 'auto'
      // } else document.body.style.overflow = 'hidden'
    },
    DeclineCookieConsent () {
      document.getElementById('cookie-notice').style.display = 'none'
      // if (!this.show) {
      //   document.body.style.overflow = 'auto'
      // } else document.body.style.overflow = 'hidden'
      setTimeout(
        () => (document.getElementById('cookie-notice').style.display = 'flex'),
        4600
      )
      this.show = false
    },
    setCookie (name, value, expires, path) {
      const date = new Date()
      date.setDate(date.getDate() + (expires || 30))
      const cookie = [
          `${name}=${value}`,
          `expires=${date.toUTCString()}`,
          `path=${path || '/'}`
      ]
      document.cookie = cookie.join(';')
    },
    getCookie (name) {
      const value = `; ${document.cookie}`
      const parts = value.split(`; ${name}`)
      return parts.length !== 2 ? undefined : parts.pop().split(';').shift()
    },
    handleView () {
      this.mobileView = window.innerWidth <= 990
      // console.log(window.innerWidth)
    },
    showMenu () {
      this.mobileView = true
    }
  },
  computed: {
    cookie () {
      return !this.getCookie(this.cookieName)
    }
  },
  created () {
    this.handleView()
    // console.log(window.innerWidth)
  }
}

window.kalendesLoaded = function () {
  // console.log('Le Widget est chargé')
  this.kalendes.setPercentHeight(90); this.kalendes.setWidth(450)
}
</script>

  <style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Neuton:wght@200;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Neuton:wght@700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@300&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Syne&display=swap');
  @import url("https://use.fontawesome.com/releases/v5.15.4/css/all.css");
  @import url('https://fonts.googleapis.com/css2?family=Amatic+SC&family=Roboto:wght@100&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap');
  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    body {
      font-size: 1rem;
      font-family: "Oswald", Tahoma;
      //background-color: #ffffff;
      color: #333;
      box-sizing: border-box;
      display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: rgb(255, 255, 255) !important;
    }
    .footerItem{
      width: 80%;
      float: right;
    }
    .transition {
border-top: 1px solid black;
    width: 100%;
    margin: 25px;
}
.container_body {
  margin-bottom: 20px;
}
.container-carousel {
  position:relative;
  width:100%;
  @media(max-width:990px) {
    width: 90%;
  }
}
.containerCTAback {
  width:40%;
  @media(max-width:950px) {
    width:60%;
  }
  @media(max-width:550px) {
    width:90%;
  }

  border: solid 1px gray;
  padding:30px;
  justify-content: center;
  align-items: center;
  display: flex;
}
    .backgroundBurger > .hours {
      font-size:10px;
      margin-left: 5px;
      position: absolute;
      top: 33%;
      @media(min-width:550px) and (max-width:990px) {
        font-size: 20px;
        top: 20%;
      }
    }
    // popup noel
    .containerTitleNoel {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
    .paragraphNoel_1, .paragraphNoel_2, .paragraphNoel_3 {
      color: white;
      text-align: center;
      font-size: 20px !important;
      font-weight: 700;
      @media(min-width:550px) and (max-width:990px) {
        font-size: 25px !important;
      }
      @media(min-width:990px) and (max-width:1800px) {
        font-size: 25px !important;
      }
      @media(min-width:1800px) and (max-width:2200px) {
        font-size: 25px !important;
      }
    }
    .title_noel {
      color:white;
      position: absolute;
      text-align: center;
      line-height: 45px;
      font-size:45px;
      top:35px;
      @media(min-width:550px) and (max-width:990px) {
        line-height: 80px;
        font-size:80px;
      }
      @media(min-width:990px) and (max-width:1800px) {
        line-height: 100px;
        font-size:100px;
      }
      @media(min-width:1800px) and (max-width:2200px) {
        line-height: 100px;
        font-size:100px;
      }
    }
    .container_paragraph_noel {
      margin-top: 15px;
    }
    .modalNoel {
  display: none; /* Par défaut, la fenêtre modale est masquée */
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5); /* Couleur de fond semi-transparente */
}

.modalNoel-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}
.modalNoel-close {
  position: absolute;
  cursor: pointer;
  //background: white;
  width: 40px;
  height: 40px;
  @media(max-width:550px) {
    right: 0;
    top:-55px;
  }
  right: -60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 10px;
  background:rgb(101, 98, 98);
  border-radius: 10px;
  padding: 5px;
}

.modalNoel-close:hover {
  color: rgb(0, 242, 255);
}

.modalContent {
  position:relative;
  @media(max-width:550px) {
    width: 470px;
    height: 250px;
  }
 @media(min-width:550px) and (max-width:990px) {
  width: 670px;
  height: 400px;
      }
      @media(min-width:990px) and (max-width:1800px) {
  width: 670px;
  height: 450px;
      }
      @media(min-width:1800px) and (max-width:2200px) {
  width: 1100px;
  height: 600px;
      }
      width: 1050px;
  height: 620px;
  //width: 90vw; /* Utilisez une unité relative comme vw (viewport width) */
  //max-width: 90%; /* Définissez une largeur maximale pour éviter qu'il ne devienne trop grand */
  background: white;
}
.imgGifNoel {
  width: 100%;
  height: 100%;
}

    // end popup noel
    #kalendes-widget-backdrop {
      z-index: 999 !important;
    }
    #kalendes-widget-container1 {
      justify-content: center;
    align-items: center;
    // width: 80%;
    // backdrop-filter: blur(5px);
    // -webkit-backdrop-filter: blur(5px);
    cursor: pointer;
    height: 75px;
    @media(max-width:550px) {
      height: 70px;
    }
    display: flex;
    color: #000;
    -o-border-image: initial;
    border-image: initial;
    background: hsla(0,0%,100%,.6);
    flex-direction: column;
    width: 60%;
    font-weight: bold;
    border: solid 1px #ffb300;
    }
    #kalendes-widget-container1:hover {
      background: #d0eef5;
      color:white;
      border-color: #d0eef5;
    }
    .containerCTA {
      width:38%;
      margin-bottom: 20px;
      @media(max-width:990px) {
        width:80%;
      }
      @media(max-width:550px) {
        width:90%;
      }
      background: rgba(255, 255, 255, 0.9);
      // height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom:20px;
      padding:30px !important;
      gap: 40px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    .titleCTA {
      font-size:30px;
      @media(max-width:550px) {
        font-size:22px;
      }
      font-family: 'Lato', sans-serif;
      color:black;
      // margin-bottom:10px;

    }
    h6.titleCTA {
      font-size: 20px;
      font-family: 'Lato', sans-serif;
      color:inherit;
    }
    h5.titleCTA {
      font-size: 20px;
      font-family: 'Lato', sans-serif;
      color:inherit;
    }
    #kalendes-widget-button2 {
      font-size: 35px;
    }

    // @media (min-width: 990px) and (max-width: 1500px) {
    //   #kalendes-widget-container1 {
    //     width: 40%;
    //     height: 75px;
    //   }
    // }
    // @media (min-width: 1500px) {
    //   #kalendes-widget-container1 {
    // width: 40%;
    // height: 75px;
    //   }
    // }
    .img_pink_october {
      background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/octobre_rose.jpg')
    }
    .cta_rdv {
      font-family: inherit;
    font-size: 36px;
    font-weight: 100;
    }
  @media (min-width: 990px) and (max-width: 1500px) {
      .cta_rdv {
      font-size: 40px;
      }
    }
    .firstContainerWidget {
      display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    justify-content: space-around;
    position:relative;
    }
    .containerLogo {
      width:50%;
      position: absolute;
      top: 0;
      margin-top: 20px !important;
  @media (min-width:990px) {
    width:20%
  }
  @media (min-width:550px) and (max-width:990px) {
    width:40%
  }
}
.img_logo {
  width:100%;
  margin-top:50px;
}
    @media (max-width:990px) {
    .footerItem {
      width: 100%;
    }
  }
    #app {
      position: relative;
      min-height: 100vh;
      width: 100%;
      // display: flex;
      justify-content: flex-end;
      height: 100%;
    }
    .background_cookie {
      background: rgba(128, 128, 128, 0.4);
      width: 100%;
      z-index: 10000;
      position: fixed;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      backdrop-filter: blur(15px);
    }
    .container_cookie {
      display: flex;
      position: fixed;
      width: 50%;
      @media(max-width:990px) {
        width: 100%;
      }
      z-index: 10000;
      background: white;
      // bottom: 0;
      text-align: center;
      border-radius: 10px;
      border: 7px solid gray;
      padding: 70px;
      @media(max-width:550px) {
        padding: 20px;
      }
      // right: 0;
    }
    #cookie-notice {
      display: flex;
      flex-direction: column;
    }
    #cookie-button {
      width: 100%;
      display: flex;
      gap: 20px;
      justify-content: center;
      margin-top: 15px;
    }
    .container-p-link {
      margin-bottom:20px;
      @media(min-width:990px) {
        margin-bottom:30px;
      }
      display: flex;
      justify-content: flex-end;
    }
    .p-link-cookies {
      cursor: pointer;
      text-decoration: underline;
      font-size:15px;
      @media(min-width:990px) {
        font-size:18px;
      }
    }
    #cookie-title-website {
      margin-bottom:20px;
      @media(min-width:990px) {
        margin-bottom:30px;
      }
      h4 {
        font-size:35px;
      }
    }
    #cookie-title-welcome {
      margin-bottom:20px;
      @media(min-width:990px) {
        margin-bottom:18px;
      }
      text-align: left;
      h4 {
        font-weight:400;
      }
    }
    .btn1 {
      cursor:pointer;
      background: #2a5858;
      color: white;
      font-size: 25px;
      width: 40%;
      height: 50px;
    }
    .btn {
      width:20%;
      background: white;
      border: none;
      padding: 10px;
    }
    #nav {
      width:100%;
    }
    .container_nav {
      position: fixed;
      height: 100%;
      overflow-y: scroll;
      width: 20%;
      background: black;
      left: 0;
      transition: 0.3s ease;
      z-index: 9999;
    }
    .backgroundNav {
      position: fixed;
    width: 0;
    height: 100vh;
    background: #0000008c;
    z-index: 999;
    transition: .4s ease;
    }
    @media (max-width: 990px) {
      .backgroundNav.open {
    width: 10%;
    right: 0;
    }
  }
    .containerTableau {
      display: flex;
      width: 100%;
      margin-bottom:50px;
    }

    /* Works on Firefox */
  * {
    //scrollbar-width: thin;
    //scrollbar-color: rgb(255, 255, 255) rgb(255, 255, 255);
  }

  /* Cibler la barre de défilement */
::-webkit-scrollbar {
  width: 1px; /* Largeur de la barre de défilement */
}

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 1px;
    display: none;
  }

  *::-webkit-scrollbar-track {
    background: rgb(0, 0, 0);
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgb(80, 80, 80);
    font-family: Sulphur Point;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
    color:black;
    transition: all 0.2s ease-in-out;
  }
  .paragraph_home_intro {
   margin: 30px 10px 30px;
  }
  a.paragraph_home {
    // border: 1px solid gray;
    // margin: -7px;
    // padding: 5px;
    font-weight: bold;
    text-decoration: none;
  }
  a.paragraph_home:hover {
    // border: 1px solid gray;
    // margin: -7px;
    // padding: 5px;
    // font-weight: bold;
    color:#62c1bd;
  }
  p {
    font-family: Syne;
    font-size: 20px;
    text-align: left;
  }
  .point {
    font-size:18px;
    color:gray;
    text-align: left;
    padding-top:5px;
    @media(min-width:1500px) {
    font-size:20px;
    }
  }
  h1.title_home {
    font-size: 18px;
    margin-top: 30px;
  }
  h1.title_tab {
    font-size:20px;
    @media(min-width:1500px) {
    font-size:22px;
    }
  }
  h1 {
    font-family:Amatic sc;
    font-size: 40px;
    color:#62c1bd;
  }
  h5 {
    font-size:20px;
    @media(min-width:1500px) {
    font-size:22px;
    }
  }
  h4 {
    font-size:20px;
    @media(min-width:1500px) {
    font-size:22px;
    }
  }
  h6 {
    font-size:18px;
    font-weight: normal !important;
    @media(min-width:1500px) {
    font-size:20px;
    }
  }
  .titlePage {
    font-family:Amatic sc;
    font-size: 40px;
    color:#62c1bd;
  }
  li {
    font-family: Nunito sans;
  }
  .list1 {
    font-family: Montserrat;
    font-size: 18px;
  }
  h2 {
    font-family:Amatic sc;
    font-size: 20px;
    color:#62c1bd;
    @media(min-width:1500px) {
    font-size:22px;
    }
  }
  h3 {
    font-family: Arial, open-sans;
    font-size: 20px;
    color:#787575;
    @media(min-width:1500px) {
    font-size:20px;
    }
  }
  li {
    text-align: left;
    // margin-left: 50px;
  }
  .container_intro_pmf {
    padding-bottom: 20px;
  }

  #burger {
   display: block;
      position: fixed;
      padding: 0;
      // width: 20px;
      height: 40px;
      border: none;
      background: transparent;
      cursor: pointer;
      top: 0;
      right: 0px;
      margin: 11px 30px 30px 30px;
      z-index: 9998;
  }
  // transitionV2

  .transitionV2{
    display: flex;
    width: 100%;
    align-items: center;
    margin:40px 1px;
    margin-top: 70px;
  }
  .leftBar{
      border-bottom: solid 1px black;
      width:15%;
  }
  .rightBar{
      border-bottom: solid 1px black;
      width:15%;
  }
  .transitionV1{
    display: flex;
    width: 100%;
    align-items: center;
    margin:40px 1px;
    margin-top: 70px;
  }
  .leftBarV1{
      border-bottom: solid 1px black;
      width:60%;
  }
  .rightBarV1{
      border-bottom: solid 1px black;
      width:60%;
  }

  .transitionV3{
    display: flex;
    width: 100%;
    align-items: center;
    margin:40px 1px;
    margin-top: 70px;
  }
  .leftBarV3{
      border-bottom: solid 1px black;
      width:80%;
  }
  .rightBarV3{
      border-bottom: solid 1px black;
      width:80%;
  }

  // end transitionv2
  .bar{
    display: block;
    width: 30px;
    height: 4px;
    background: black;
    margin-top: 10px;
  }
  .tableau1 {
    background: #f9c2c2;
    width: 50%;
    padding-top:30px;
    padding-bottom:30px;
  }
  .tableau2 {
    background: #c2f9e6;
    width: 50%;
    padding-top:30px;
    padding-bottom:30px;
  }
  .tableau3 {
    background: rgb(249, 194, 194);
    width: 100%;
    padding-top:30px;
    padding-bottom:30px;
    margin-top:30px;
  }
  .tableau2_alone {
    background: #c2f9e6;
    width: 100%;
    padding-top:30px;
    padding-bottom:30px;
  }
  .bar::before, .bar::after {
      content:'';
      display:block;
      position:absolute;
      width:40px;
      height:4px;
      background:rgb(0, 0, 0);
  }
  .bar::before {
    transform:translateY(12px);
  }

  .bar::after {
    transform:translateY(-12px);
  }
  // page-perruque :
  .PerruquesRelooking {
    width: 80%;
    float: right;
    @media(max-width:990px) {
      width: 100%;
    }
  }
  // logos
  .fbSvg, .instSvg {
    color:white;
  }
  // end logos
  .container_choixPerruque {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    gap:5px;
    margin-bottom: 40px;
  }
  .grid-container {
    padding: 0;
    display: inline-grid;
    width: 25%;
    position: relative;
    cursor: pointer;
    img {
      width: 100%;
    }
    @media(max-width:990px) {
      min-width: 47%;
    }

  }
  .voile,.link_voile {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    background: rgb(203 153 145 / 55%);
    z-index: 1;
    opacity: 0.8;
  }
  .link_voileMobile {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    z-index: 1;
    opacity: 0.8;
  }
  .container_name_price {
    position: absolute;
    background: #587880;
    opacity: 0.8;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 8;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    padding-top:10px;
    padding-bottom: 10px;
    transition : all 0.5s ease-in-out;
    h2 {
      font-size:20px;
      color:white;
      font-family: Arial, Helvetica, sans-serif;
      @media(min-width:1600px) {
        font-size:40px;
      }
    }
  }
  .link_perruque {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-indent: -999999px;
    outline: none !important;
    z-index: 2;
    color: #9c2636;
    text-decoration: none;
  }
  .container_link {
    width: 100%;
    height: 100%;
    background: black;
    z-index: 9999;
  }

  .container_name_priceBis {
    position: absolute;
    background: #587880;
    opacity: 0.8;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 8;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    padding-top:10px;
    padding-bottom: 10px;
    transition : all 0.5s ease-in-out;
    h2 {
      font-size:20px;
      color:white;
      font-family: Arial, Helvetica, sans-serif;
      @media(min-width:1600px) {
        font-size:40px;
      }
    }
  }
  // fin page-perruque
// page qui sommes nous

ul li.list_dtails {
  font-weight: normal !important;
}
//fin page qui sommes nous
  @media (max-width: 590px) {
    p {
      font-size:16px;
    }
  }
  @media (min-width: 590px) and (max-width: 990px) {
    p {
      font-size:18px;
    }
    h4, .links {
      font-size: 16px;
      font-family: Nunito sans;
    }
    .container_links_bis {
      width:50%;
    }
  }
  @media (max-width: 990px) {
    .container_nav {
      width:40%;
      left:-1000px;
      &.open {
          left:0px
      }
    }
     .list1 {
    font-size: 13px;
   }
    // .container_cookie {
    //   right: 15%;
    // }
    .containerTableau {
      flex-direction: column;
    }
    .tableau1, .tableau2 {
    // background: rgb(249, 238, 194);
    width: 100%;
    // padding-top:30px;
    // padding-bottom:30px;
  }
    .bar::before {
    transform:translateY(12px);
    &.open1 {
      transform:rotate(45deg);
    }
  }

    // #burger {
    //   right: 0px;
    //   &.open1 {
    //     right:100px

    //   }
    // }
  }
  @media (min-width: 990px){
  #burger {
      display: none;
   }
  }
  @media (min-width: 990px) and (max-width:2000px) {
  p {
    font-size: 18px !important;
   }
   h1.title_tab, h2, h3, h4, h5, h6 {
    font-size: 22px;
   }
   .paragrapFooter, .links {
    font-size: 14px !important;
   }
  }
.title_conseils {
  text-align: left;
  margin:0px 20px 20px 20px;
  font-weight: bold;
}
.paragraphConseils {
  text-align: left;
  margin:0px 20px 20px 20px;
}
.listeConseils > li {
  text-align: left;
    list-style: none;
    font-family: Syne;
    margin: 10px 20px 0px 25px;
}
.paragraphBis {
  margin: 20px 0px 20px 20px;
  text-align: left;
}
// dropdown
.container_flex_dropdown {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media (max-width:550px) {
    justify-content: center;
  }
  gap:20px;
}
.dropdownContainer{
  width: 30%;
  @media (max-width:1350px) {
    width: 30%;
  }
  @media (max-width:550px) {
    width: 100%;
  }
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    padding: 10px 11px;
    transition: 0.4s ease;
    border: 1px solid gray;
    background: white;
  cursor: pointer;
  .selected {
    color: rgb(0, 0, 0);
    pointer-events: none;
    user-select: none;
  }
  .arrowDownRight {
    width: 10%;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    color: rgb(0, 0, 0);
  }
  .dropDownList {
    border: solid 1px gray;
    z-index: 9999;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -2px rgb(0 0 0 / 10%);
    background: white;
    transition: 0.4s ease;
    .listOption {
      background: white;
      display: flex;
      justify-content: right;
      align-items: center;
      height: 40px;
      padding-right: 10px;
    }
  }
  .listOption:hover {
    background:#62c1bd;
    color:white;
  }
}
.hidden {
  display:none;
}
.tansitionDropDown {
  border-top:1px solid gray;
  margin: 30px 0px;
  width: 100%;
}
.tansitionDropDownV2 {
  //border-top:1px solid gray;
  margin-bottom: 30px;
  width: 100%;
}
.container_dropSearch {
  display: flex;
  width:100%;
  padding: 0px 30px;
  @media (max-width:550px) {
    padding: 0px 10px;
  }
}
.inputSearch {
  @media (max-width: 990px) {
    display: none;
  }
}
// end dropdown
// qui sommes nous

.container_objectifs {
  display: flex;
  flex-direction: column;
  background: #e8e8e8;
}
.containerProfils {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 70px;
  padding: 10px;
  @media(max-width:990px) {
    flex-direction: column;
  }
}
.container_content {
  padding: 25px;
  @media(max-width:550px) {
    padding:5px;
  }
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap:55px;

}
.profilFlo {
  padding: 30px 30px;
  @media(max-width:990px) {
    padding: 8px 8px;
  }
  background: white;
  display: flex;
  flex-direction: row-reverse;
  @media(max-width:990px) {
    flex-direction: column;
  }
}
.profilLeati {
  padding: 30px 30px;
  @media(max-width:990px) {
    padding: 8px 8px;

  }
  background: white;
  display: flex;
  flex-direction: row;
  @media(max-width:990px) {
    flex-direction: column;
  }
}
.containerInfos {
  display: flex;
  flex-direction: column;
  margin-left:40px;
  @media(max-width:990px) {
    margin-left:0;
  }
}
.containerNom {
  width: 100%;
  display: flex;
  justify-content: left;
  margin: 0 20px 5px;
}
.containerComps {
  margin-top: 25px;
}
.QuiSommesNous {
  background: #eaeaea;
}
.imgContainerFlo{
  width: 40%;
  @media(max-width:990px) {
    width: 100%;
    margin-bottom:25px;
  }
  background: #ffc1d6;
  display: flex;
    justify-content: center;
    align-items: center;
}
.imgContainerLaeti{
  width: 40%;
  @media(max-width:990px) {
    width: 100%;
    margin-bottom:25px;
  }
    background: #b6eef7;
    display: flex;
    justify-content: center;
    align-items: center;
}
.title_conseils_flo {
  padding-bottom: 20px;
  font-size: 30px;
  font-family:Arial, Helvetica, sans-serif;
  color:#e23d74
}
.title_conseils_laeti {
  padding-bottom: 20px;
  font-size: 30px;
  font-family:Arial, Helvetica, sans-serif;
  color:#287b87;

}
.img_flo, .img_laeti{
  max-width: 80%;
  max-height: 80%;
  @media(max-width:990px) {
    max-width: 60%;
    max-height: 60%;
  }
}
.container_details_story_salon {
  background: white;
  padding: 30px;
  margin:10px;
  padding: 40px 70px;
  @media(max-width:990px){
    padding: 18px 18px;
  }
}
.title_story_salon {
  color:#ffdd58;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 40px;
  margin-bottom: 70px;
  font-weight: bold;;
}
// end qui sommes nous ?
#cookie-message {
      margin-top:20px;
      margin-bottom:20px;
      @media(min-width:990px) {
        p {
          font-size: 14px !important;
          line-height: 22px;
        }
        margin-bottom:30px;
      }
      font-size: 20px;
      line-height: 25px;
      @media(min-width:990px) {
        line-height: 28px;
      }
    }
    .sub_container_coupe_courte{
 display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}
.container_coupe_courte{
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    // z-index: 0;
    background: #ff7a001a;
}
  </style>
